import React, { Component } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';
//import MenuIcon from './telko.svg';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import SubscriptionModule from '../subscription_module/subscription_module'
import axios from 'axios';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import './top_nav_bar.css';

const styles = {
    button: {
        borderRadius: 0,
        height: 20,
        width: 1,
        marginLeft: 5
    }
};

class TopNavBar extends Component {
    state = {
        anchorUserMenu: null,
        anchorNavMenu: null,
        importInvoiceSubscription: false,
        importPaidSubscription: false,
        invoiceCsvUrl: null,
        paidCsvUrl: null,
        showInvoiceCsvUrl: false,
        showPaidCsvUrl: false
    };

    handleLogout = (event) => {
        event.preventDefault();
        this.props.loginstate.doLogout();
        this.handleUserClose();
    }

    goToAccountSettings = (event) => {
        event.preventDefault();
        this.props.history.push("/userinfo/" + this.props.loginstate.username);
        this.handleUserClose();
    }


    handleUserMenu = (event) => {
        event.preventDefault();
        this.setState({
            anchorUserMenu: event.currentTarget
        });
    }

    handleUserClose = (event) => {
        this.setState({
            anchorUserMenu: null
        });
    }

    handleNavMenu = (event) => {
        event.preventDefault();
        this.setState({
            anchorNavMenu: event.currentTarget
        });
    }

    handleNavClose = () => {
        this.setState({
            anchorNavMenu: null
        });
    }

    goToUserManagement = (event) => {
        event.preventDefault();
        this.props.history.push('/adminmainpage');
        this.handleNavClose();
    }

    goToHome = (event) => {
        event.preventDefault();
        this.props.history.push('/');
        this.handleNavClose();
    }

    goToNewUserRegisteration = (event) => {
        event.preventDefault();
        this.props.history.push('/signup');
        this.handleNavClose();
    }

    handleDownloadInvoiceCsv = () => {
        const url = `${process.env.REACT_APP_BACKEND_URL}/subscriptioninvoice`;
        const headers = { Authorization: this.props.loginstate.token };
        axios.get(url,
            { headers }
        ).then(response => {
            if (response && response.data) {
                const exportCsvData = "\uFEFF" + response.data;
                const blob = new Blob([exportCsvData], { encoding: "UTF-8", type: 'charset=UTF-8;Text/Csv' });
                const urlForEportData = window.URL.createObjectURL(blob);
                this.setState({
                    invoiceCsvUrl: urlForEportData,
                    showInvoiceCsvUrl: true
                });
            }
        })
            .catch(error => {
                this.setState({ showInvoiceCsvUrl: false });
            });

    }

    handleDownloadPaidCsv = () => {
        const url = `${process.env.REACT_APP_BACKEND_URL}/subscriptionpaid`;
        const headers = { Authorization: this.props.loginstate.token };
        axios.get(url,
            { headers }
        ).then(response => {
            if (response && response.data) {
                const exportCsvData = "\uFEFF" + response.data;
                const blob = new Blob([exportCsvData], { encoding: "UTF-8", type: 'charset=UTF-8;Text/Csv' });
                const urlForEportData = window.URL.createObjectURL(blob);
                this.setState({
                    paidCsvUrl: urlForEportData,
                    showPaidCsvUrl: true
                });
            }
        })
            .catch(error => {
                this.setState({ showPaidCsvUrl: false });
            });

    }

    componentDidMount() {
    }

    downloadLatestInvoiceCsv = () => {
        this.handleDownloadInvoiceCsv();
        this.handleNavClose();
    }

    downloadLatestPaidCsv = () => {
        this.handleDownloadPaidCsv();
        this.handleNavClose();
    }

    closeDownloadLink = () => {
        this.setState({ invoiceCsvUrl: null, paidCsvUrl: null, showInvoiceCsvUrl: false, showPaidCsvUrl: false });
    }

    render() {
        // const { classes } = this.props;

        return (
            <div className='topNavBar' style={{height:55}}>
                <Dialog
                    open={this.state.showInvoiceCsvUrl || this.state.showPaidCsvUrl}
                    onClose={this.closeDownloadLink}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogContent>
                        {this.state.invoiceCsvUrl && <a href={this.state.invoiceCsvUrl} download='Subscription.csv'>Click here to download Invoice Subscription Information</a>}
                        {this.state.paidCsvUrl && <a href={this.state.paidCsvUrl} download='Subscription.csv'>Click here to download Paid Subscription Information</a>}
                    </DialogContent>
                </Dialog>

                    <SubscriptionModule invoice={this.state.importInvoiceSubscription} open={this.state.importInvoiceSubscription} close={() => { this.setState({ importInvoiceSubscription: false }) }} loginstate={this.props.loginstate} />
                    <SubscriptionModule open={this.state.importPaidSubscription} close={() => { this.setState({ importPaidSubscription: false }) }} loginstate={this.props.loginstate} />

                <AppBar position="static" style={{ 'backgroundColor': 'rgb(46, 56, 72)', 'height': '3.5em' }}>
                    <Toolbar >
                        <IconButton
                            color='inherit'
                            aria-label="Menu"
                            aria-owns={this.state.anchorNavMenu ? 'menu-nav-bar' : undefined}
                            aria-haspopup="true"
                            onClick={this.handleNavMenu}
                            className='NavBarButton'
                            style={{
                                ...styles.button
                            }}
                        >
                        </IconButton>
                        <Menu
                            id="menu-nav-bar"
                            anchorEl={this.state.anchorNavMenu}
                            transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            open={this.state.anchorNavMenu ? true : false}
                            onClose={this.handleNavClose}
                        >
                            <MenuItem onClick={this.goToHome}>Tigris</MenuItem>
                            <MenuItem onClick={this.goToUserManagement}>User Management</MenuItem>
                            <MenuItem onClick={this.goToNewUserRegisteration}>New User Registration</MenuItem>
                        </Menu>

                        <Typography variant="h5" color="inherit" className='topNavBarHeading'>
                            {this.props.heading}
                        </Typography>

                        {this.props.loginstate.isAuthenticated && <div>
                            <Button
                                aria-owns={this.state.anchorUserMenu ? 'menu-user-bar' : undefined}
                                aria-haspopup="true"
                                onClick={this.handleUserMenu}
                                color="inherit"
                            >
                                <AccountCircle />
                                {this.props.loginstate.name_of_user}
                            </Button>
                            <Menu
                                id="menu-user-bar"
                                anchorEl={this.state.anchorUserMenu}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                open={this.state.anchorUserMenu ? true : false}
                                onClose={this.handleUserClose}
                            >
                                <MenuItem onClick={this.goToAccountSettings}>My Account</MenuItem>
                                <MenuItem onClick={this.handleLogout}>Log Out</MenuItem>
                            </Menu>
                        </div>}
                    </Toolbar>
                </AppBar>
            </div>
        );
    }

}

export default withRouter(withStyles(styles, { withTheme: true })(TopNavBar));
